<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="mx-auto card"
      :class="hover ? 'hovercard' : ''"
      max-width="330"
    >
      <router-link
        :to="detalleVehiculoRoute"
        target="_blank"
        :class="`${$vuetify.theme.dark ? 'white--text' : ''}`"
      >
        <v-lazy
          v-model="isActive"
          :options="{ threshold: 0.5 }"
          min-height="200"
          transition="fade-transition"
        >
          <v-img :lazy-src="urlImagen" :src="urlImagen"></v-img>
        </v-lazy>
        <div class="chip">{{ vehiculo.marca }}</div>
        <v-card-title class="titulo">
          {{ vehiculo.marca }} {{ vehiculo.anio }} <br />
          {{ vehiculo.modeloBase }}
        </v-card-title>

        <v-card-text
          class="descripcion"
          style="
            font-size: 12px !important;
            font-weight: 500 !important;
            line-height: 16px !important;
          "
        >
          {{ vehiculo.transmision }} - {{ vehiculo.km }}km -
          {{ vehiculo.motor }}
          <br />
          EMPRESA:
          <span
            v-if="
              empresaVehiculo !=
              'Este vehículo no está o está mal registrado en AUTODEALER'
            "
            >{{ empresaVehiculo }}</span
          >
          <br />
          {{ vehiculo.descripcionUbicacion }}
          <br />
          Patente: {{ vehiculo.patente }}
          <v-tooltip
            v-if="
              empresaVehiculo ==
              'Este vehículo no está o está mal registrado en AUTODEALER'
            "
            left
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="orange" class="mb-1" small>
                fas fa-exclamation-triangle
              </v-icon>
            </template>
            <div>
              {{ empresaVehiculo }}
            </div>
          </v-tooltip>
          <br />
          <span style="line-height: 18px !important"
            >PRECIO: {{ precioVehiculo }}</span
          >
        </v-card-text>
      </router-link>
      <v-col class="btn">
        <div style="" v-if="permiso">
          <!-- <router-link :to="detalleVehiculoRoute">
            <v-btn
              class="mr-2"
              style="font-size: 9px"
              x-small
              @click.prevent="abrirNuevaPestana"
              color="primary"
              >MAS INFORMACION</v-btn
            >
          </router-link> -->
          <BtnConfirmar
            v-if="permiso"
            nombre="Inhabilitar"
            clase="custom-button-style"
            sin_icono
            :color="'warning'"
            :title="'Inhabilitar'"
            @action="inhabilitar"
            :texto="`¿Desea inhabilitar el vehiculo con patente: <strong>${vehiculo.patente}</strong>?`"
          />
        </div>
      </v-col>
    </v-card>
  </v-hover>
</template>

<script>
import { format_money_round } from '../../util/utils'
import BtnConfirmar from '../util/BtnConfirmar.vue'

export default {
  props: {
    vehiculo: Object,
    permiso: Boolean
  },
  data() {
    return {
      isActive: false
    }
  },

  computed: {
    detalleVehiculoRoute() {
      return {
        name: 'Detalle de Vehiculo',
        params: { id: this.vehiculo?.patente, datosVehiculo: this.vehiculo }
      }
    },

    precioVehiculo() {
      return this.vehiculo ? format_money_round(this.vehiculo.precio) : ''
    },

    urlImagen() {
      return this.vehiculo && this.vehiculo.UrlImagenesMini[0]
        ? this.vehiculo.UrlImagenesMini[0]
        : '/sinImagenes.webp'
    },

    empresaVehiculo() {
      if (this.vehiculo) {
        switch (this.vehiculo.familia_id) {
          case 1:
            return 'DYCAR'
          case 2:
            return 'EURODYCAR'
          case 3:
            return 'GDB'
          case 4:
            return 'EUROFRANCIA'
          case 5:
            return 'RADA'
          default:
            return 'Este vehículo no está o está mal registrado en AUTODEALER'
        }
      }
      return ''
    }
  },

  methods: {
    abrirNuevaPestana() {
      const routeData = this.$router.resolve(this.detalleVehiculoRoute)
      window.open(routeData.href, '_blank')
    },

    async inhabilitar() {
      this.$store.state.loading = true
      await this.$store
        .dispatch('usados/inhabilitarVehiculo', {
          familia_id: this.vehiculo.familia_id,
          patente: this.vehiculo.patente,
          firebase: true
        })
        .then(res => {
          if (res.exito) {
            this.$emit('habilitar', this.vehiculo.patente)
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    },

    // PRUEBA
    async habilitar() {
      this.$store.state.loading = true
      await this.$store
        .dispatch('usados/habilitarVehiculo', {
          familia_id: this.vehiculo.familia_id,
          patente: this.vehiculo.patente
        })
        .then(res => {
          if (res.exito) {
            this.$emit('habilitar', this.vehiculo.patente)
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    }
  },

  components: {
    BtnConfirmar
  }
}
</script>

<style>
.card {
  position: relative;
  transition: all ease 200ms;
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

.titulo {
  margin-top: -15px;
}

.chip {
  background: #1875c7;
  display: inline;
  padding: 2px 5px;
  border-radius: 5px;
  color: white;
  position: absolute;
  top: 12px;
  left: 12px;
  cursor: default;
  font-size: 13px;
  font-weight: 700;
}

.btn {
  margin-top: -10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.descripcion {
  margin-top: -10px !important;
}
.custom-button-style {
  /* font-size: 9px !important; */
  margin-right: auto;
}

.hovercard {
  background: #d3dcdd !important;
  scale: 1.03;
  z-index: 1.5;
  &.titulo {
    color: #f2f2f2 !important;
  }

  &.descripcion {
    color: #f2f2f2 !important;
  }
}
</style>
