<template>
  <div>
    <v-row>
      <v-col style="padding-bottom: 0!important;" cols="12">
        <h1 color=" blue-grey darken-4">{{ vehiculo[0].anio }} {{ vehiculo[0].marca }}
          {{ vehiculo[0].modelo }}</h1>
      </v-col>
      <v-col cols="12" sm="7">
        <img class="imagen" v-if="vehiculo[0].UrlImagenesGrandes.length == 0" src="/sinImagenes.webp" alt="Sin imagenes">
        <v-carousel v-else show-arrows-on-hover cycle height="500" hide-delimiter-background
          delimiter-icon="fas fa-minus">
          <v-carousel-item :src="slide" contain v-for="(slide, i) in vehiculo[0].UrlImagenesGrandes" :key="i">

          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" sm="5">
        <v-card class="mx-auto mt-4">
          <v-list flat>
            <v-subheader class="dflex" style="justify-content: space-between;">
              <h3 @click="copyText(precioVehiculo)" style="cursor: pointer;">
                Precio: {{ precioVehiculo }}
              </h3>
              <v-icon @click="copiarDatos()" small title="copiar datos" style="cursor: pointer;">far fa-clipboard</v-icon>
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item-group color="primary">
              <v-list-item dense @click="copyText(empresaVehiculo)">
                <v-list-item-icon>
                  <v-icon small>far fa-building</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="content">
                  <v-list-item-title>Empresa: {{ empresaVehiculo }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              
              <v-list-item dense @click="copyText(`${vehiculo[0].descripcionUbicacion} - ${vehiculo[0].direccion}` )">
                <v-list-item-icon>
                  <v-icon small>fas fa-map-marker-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :title="`${vehiculo[0].descripcionUbicacion}`">Ubicación: {{ vehiculo[0].descripcionUbicacion }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense @click="copyText(vehiculo[0].patente)">
                <v-list-item-icon>
                  <v-icon small>fas fa-car</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="content">
                  <v-list-item-title>Patente: {{ vehiculo[0].patente }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense @click="copyText(vehiculo[0].km)">
                <v-list-item-icon>
                  <v-icon small>fas fa-tachometer-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Kilómetros: {{ vehiculo[0].km }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense @click="copyText(vehiculo[0].transmision)">
                <v-list-item-icon>
                  <v-icon small>fas fa-code-branch</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Transmisión: {{ vehiculo[0].transmision }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense @click="copyText(vehiculo[0].motor)">
                <v-list-item-icon>
                  <v-icon small>fas fa-cogs</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Motor: {{ vehiculo[0].motor }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense @click="copyText(vehiculo[0].combustible)">
                <v-list-item-icon>
                  <v-icon small>fas fa-gas-pump</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Combustible: {{ vehiculo[0].combustible }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense @click="copyText(vehiculo[0].color)">
                <v-list-item-icon>
                  <v-icon small>fas fa-palette</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Color: {{ vehiculo[0].color }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item dense @click="copyText(vehiculo[0].puertas)">
                <v-list-item-icon>
                  <v-icon small>fas fa-car-side</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Puertas: {{ vehiculo[0].puertas }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

            </v-list-item-group>
          </v-list>
        </v-card>
        <v-subheader class="footer" style="font-size:15px;">*Los precios expresados por unidad no incluyen gastos de
          transferencia ni administrativos</v-subheader>
      </v-col>

    </v-row>

  </div>
</template>
<script>
import { format_money_round } from '../../util/utils';
export default {
  data() {
    return {
      vehiculo: null
    }
  },
  computed: {
    patente() {
      return this.$route.params.id;
    },

    precioVehiculo() {
      return format_money_round(this.vehiculo[0].precio)
    },

    urlImagen() {
      return this.vehiculo && this.vehiculo[0].UrlImagenesGrandes[0] ? this.vehiculo[0].UrlImagenesGrandes[0] : '/sinImagenes.webp';
    },

    empresaVehiculo() {
      if (this.vehiculo[0]) {
        switch (this.vehiculo[0].familia_id) {
          case 1:
            return "DYCAR";
          case 2:
            return 'EURODYCAR';
          case 3:
            return 'GDB';
          case 4:
            return 'EUROFRANCIA';
          case 5:
            return 'RADA';
          default:
            return 'Este vehículo no está registrado en AUTODEALER';
        }
      }
      return '';
    }
  },
  created() {
    this.getVehiculo()
  },

  methods: {
    getVehiculo() {
      const vehiculos = JSON.parse(localStorage.getItem('vehiculos'))

      this.vehiculo = vehiculos.filter(vehiculo => vehiculo.patente == this.patente)
    },

    copiarDatos() {
      const text = `
      Vehiculo: ${this.vehiculo[0].anio} ${this.vehiculo[0].marca} ${this.vehiculo[0].modelo}
      Precio: ${this.precioVehiculo}
      Patente: ${this.vehiculo[0].patente}
      Ubicación: ${this.vehiculo[0].descripcionUbicacion} - ${this.vehiculo[0].direccion}
      Kilómetros: ${this.vehiculo[0].km}
      Transmisión: ${this.vehiculo[0].transmision}
      Motor: ${this.vehiculo[0].motor}
      Combustible: ${this.vehiculo[0].combustible}
      Color: ${this.vehiculo[0].color}
      Puertas: ${this.vehiculo[0].puertas}
      `

      navigator.clipboard.writeText(text)
        .then(() => {
          this.$store.dispatch('show_snackbar', {
            text: `Datos copiados: ${text}`,
            color: 'info'
          })
        })
        .catch(err => {
          this.$store.dispatch('show_snackbar', {
            text: "Error al copiar texto: ", err,
            color: 'error'
          });
        });
    },

    copyText(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.$store.dispatch('show_snackbar', {
            text: `Texto copiado: ${text}`,
            color: 'info'
          })
        })
        .catch(err => {
          this.$store.dispatch('show_snackbar', {
            text: "Error al copiar texto: ", err,
            color: 'error'
          });
        });
    }
  },
}
</script>

<style>
.imagen {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.footer {
  width: 100%;
  height: auto;
  padding-top: 24px;
}

.imagen_carrousel {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
