<template>
  <v-dialog v-model="dialog" max-width="800" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Comentarios</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-2 align-center">
          <v-col cols="12">
            <h5 v-for="(item, index) in vehiculo.memos" :key="index">
              {{ item }}
            </h5>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
    };
  },

  computed: {

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  props: {
    value: Boolean,
    vehiculo: Object
  },

};
</script>
