var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mx-auto card",class:hover ? 'hovercard' : '',attrs:{"max-width":"330"}},[_c('router-link',{class:`${_vm.$vuetify.theme.dark ? 'white--text' : ''}`,attrs:{"to":_vm.detalleVehiculoRoute,"target":"_blank"}},[_c('v-lazy',{attrs:{"options":{ threshold: 0.5 },"min-height":"200","transition":"fade-transition"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-img',{attrs:{"lazy-src":_vm.urlImagen,"src":_vm.urlImagen}})],1),_c('div',{staticClass:"chip"},[_vm._v(_vm._s(_vm.vehiculo.marca))]),_c('v-card-title',{staticClass:"titulo"},[_vm._v(" "+_vm._s(_vm.vehiculo.marca)+" "+_vm._s(_vm.vehiculo.anio)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.vehiculo.modeloBase)+" ")]),_c('v-card-text',{staticClass:"descripcion",staticStyle:{"font-size":"12px !important","font-weight":"500 !important","line-height":"16px !important"}},[_vm._v(" "+_vm._s(_vm.vehiculo.transmision)+" - "+_vm._s(_vm.vehiculo.km)+"km - "+_vm._s(_vm.vehiculo.motor)+" "),_c('br'),_vm._v(" EMPRESA: "),(
            _vm.empresaVehiculo !=
            'Este vehículo no está o está mal registrado en AUTODEALER'
          )?_c('span',[_vm._v(_vm._s(_vm.empresaVehiculo))]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(_vm.vehiculo.descripcionUbicacion)+" "),_c('br'),_vm._v(" Patente: "+_vm._s(_vm.vehiculo.patente)+" "),(
            _vm.empresaVehiculo ==
            'Este vehículo no está o está mal registrado en AUTODEALER'
          )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mb-1",attrs:{"color":"orange","small":""}},on),[_vm._v(" fas fa-exclamation-triangle ")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.empresaVehiculo)+" ")])]):_vm._e(),_c('br'),_c('span',{staticStyle:{"line-height":"18px !important"}},[_vm._v("PRECIO: "+_vm._s(_vm.precioVehiculo))])],1)],1),_c('v-col',{staticClass:"btn"},[(_vm.permiso)?_c('div',{},[(_vm.permiso)?_c('BtnConfirmar',{attrs:{"nombre":"Inhabilitar","clase":"custom-button-style","sin_icono":"","color":'warning',"title":'Inhabilitar',"texto":`¿Desea inhabilitar el vehiculo con patente: <strong>${_vm.vehiculo.patente}</strong>?`},on:{"action":_vm.inhabilitar}}):_vm._e()],1):_vm._e()])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }